import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// const AssistantChat = React.lazy(() => import('./pages/assistant/AssistantChat'))
//
// const PlanningWhiteboard = React.lazy(() => import('./pages/planning/PlanningWhiteboard'))
// const ExternalStakeholders = React.lazy(() => import('./pages/settings/ExternalStakeholders'))
// const InternalStakeholders = React.lazy(() => import('./pages/settings/InternalStakeholders'))
// const RegulatoryStakeholders = React.lazy(() => import('./pages/settings/RegulatoryStakeholders'))
// const LawEnforcement = React.lazy(() => import('./pages/settings/LawEnforcement'))
//
// const ResponseTeams = React.lazy(() => import('./pages/teams/ResponseTeams'))
// const CommsTemplates = React.lazy(() => import('./pages/templates/CommsTemplates'))
// const UserProfile = React.lazy(() => import('./pages/User/UserProfile'))
// const UserSettings = React.lazy(() => import('./pages/User/UserSettings'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />

            {/*<Route path="/assistant/chat" name="Assistant Chat" element={<AssistantChat />} />*/}
            {/*<Route path="/planning/whiteboard" name="PLanning Whiteboard" element={<PlanningWhiteboard />} />*/}

            {/*<Route path="/settings/external-stakeholders" name="External Stakeholders" element={<ExternalStakeholders />} />*/}
            {/*<Route path="/settings/internal-stakeholders" name="Internal Stakeholders" element={<InternalStakeholders />} />*/}
            {/*<Route path="/settings/regulatory-stakeholders" name="Regulatory Stakeholders" element={<RegulatoryStakeholders />} />*/}
            {/*<Route path="/settings/law-enforcement" name="Law Enforcement" element={<LawEnforcement />} />*/}

            {/*<Route path="/teams/response" name="Response Teams" element={<ResponseTeams />} />*/}
            {/*<Route path="/templates/comms" name="Comms Templates" element={<CommsTemplates />} />*/}
            {/*<Route path="/user/profile" name="User Profile" element={<UserProfile />} />*/}
            {/*<Route path="/user/settings" name="User Settings" element={<UserSettings />} />*/}

            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
